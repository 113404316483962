import { FooterMobile, HeaderMobile } from 'components'
import React, { useEffect, useState } from 'react'

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

const MobileComingSoon = () => {
  const [showCountdown, setShowCountdown] = useState(true)

  const desiredDate = '2025-01-06' // Format: YYYY-MM-DD

  const calculateTimeLeft = () => {
    const difference = +new Date(desiredDate) - +new Date()
    let timeLeft = {}
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      }
    }
    return timeLeft
  }

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())

  useEffect(() => {
    if (isObjectEmpty(timeLeft)) {
      setShowCountdown(false)
    } else {
      setShowCountdown(true)
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft())
      }, 1000)
      return () => clearTimeout(timer)
    }
  })

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time
  }

  return (
    <div>
      <HeaderMobile />

      <div style={{ padding: '3rem 2rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.5rem' }}>
          We Will <br /> Be Back Soon
        </div>
        <div style={{ display: 'flex', columnGap: '2.25rem', marginTop: '2.5rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '1rem' }}>
            <span style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.125rem', color: '#71869C' }}>
              Days
            </span>
            <span style={{ fontFamily: 'Gilroy-Bold', fontSize: '3rem' }}>
              {formatTime(timeLeft.days)}
            </span>
          </div>
          <div className='countdown-timer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '1rem' }}>
            <span style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.125rem', color: '#71869C' }}>
              Hours
            </span>
            <span style={{ fontFamily: 'Gilroy-Bold', fontSize: '3rem' }}>
              {formatTime(timeLeft.hours)}
            </span>
          </div>
          <div className='countdown-timer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '1rem' }}>
            <span style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.125rem', color: '#71869C' }}>
              Minutes
            </span>
            <span style={{ fontFamily: 'Gilroy-Bold', fontSize: '3rem' }}>
              {formatTime(timeLeft.minutes)}
            </span>
          </div>
          <div className='countdown-timer' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '1rem' }}>
            <span style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.125rem', color: '#71869C' }}>
              Seconds
            </span>
            <span style={{ fontFamily: 'Gilroy-Bold', fontSize: '3rem' }}>
              {formatTime(timeLeft.seconds)}
            </span>
          </div>
        </div>
        <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1.5rem', color: '#71869C', lineHeight: '2.5rem', marginTop: '2.5rem' }}>
          We're strong believers that the best solutions come from gathering new insights and pushing conventional boundaries.
        </div>
      </div>

      <FooterMobile />
    </div>
  )
}

export default MobileComingSoon
