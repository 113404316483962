import React, { useContext, useState } from 'react'
import { Footer, Header } from 'components'
import { Context } from 'helpers/language-provider'

import { H3, H5 } from 'assets/images'
import ProductH1 from './h1'
import ProductH3 from './h3'
import ProductH5 from './h5'
import ProductH3i from './h3i'
import { useParams } from 'react-router-dom'

const Product = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')
  const { type } = useParams()

  return (
    <div>

      <Header />

      { type === 'h1' && <ProductH1 /> }
      { type === 'h3' && <ProductH3 /> }
      { type === 'h3i' && <ProductH3i /> }
      { type === 'h5' && <ProductH5 /> }

      { type === 'h1' && <Footer /> }
      { type === 'h3' && <Footer /> }
      { type === 'h3i' && <Footer backgroundColor='#F4F7FA' /> }
      { type === 'h5' && <Footer backgroundColor='#0C1D28' /> }

    </div>
  )
}

export default Product
