import * as React from "react";

const IonLeaf = ({ size = '72' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 72 72"
  >
    <path
      fill="url(#paint0_linear_7449_1013)"
      d="M37.967 22.688a2.25 2.25 0 0 1 .096 3.181C28.309 36.223 22.375 46.613 19.35 58.84c4.5 1.65 9.453 1.796 14.285.371 6.41-1.89 11.996-6.292 16.152-12.725 3.71-5.743 4.676-11.484 5.61-17.037 1.178-7.006 2.291-13.624 8.978-19.94.74-.702 1.037-1.66.796-2.575-.281-1.047-1.108-1.748-2.475-2.084-3.2-.788-12.165-.282-21.63 1.933-15.208 3.557-22.903 9.232-26.682 13.368-4.878 5.344-7.58 12.322-7.58 19.256a26 26 0 0 0 .288 3.905c.888 5.865 3.834 10.748 8.12 13.5 3.274-12.585 9.5-23.335 19.57-34.031a2.25 2.25 0 0 1 3.185-.092M17.972 65.731a78 78 0 0 1 1.374-6.89 22 22 0 0 1-3.612-1.707q-.263-.153-.521-.322a82 82 0 0 0-1.688 8.235 2.25 2.25 0 1 0 4.447.685z"
    ></path>
    <path
      fill="url(#paint1_linear_7449_1013)"
      d="m15.506 58.497-.5-3c-1-4 9-31 18.5-34 7.6-2.4 6.833 2.333 5.5 5-11.6 13.2-16.833 26.5-18 31.5l-3 1.5z"
    ></path>
    <path
      fill="#fff"
      d="m29.368 36.545-9.52-2.906q-1.005-.3-1.145-1.339t.722-1.609l19.564-13.207a1.3 1.3 0 0 1 .546-.222q.305-.052.798.03.82.143 1.133.914.312.772-.228 1.441l-7.172 9.251 9.52 2.907q1.006.3 1.144 1.338.14 1.038-.721 1.61L24.444 47.958q-.24.17-.546.224-.305.054-.798-.032-.82-.143-1.133-.914-.312-.772.228-1.44z"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_7449_1013"
        x1="36.029"
        x2="36.029"
        y1="4.5"
        y2="67.687"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1ECECA"></stop>
        <stop offset="1" stopColor="#0B4571"></stop>
      </linearGradient>
      <linearGradient
        id="paint1_linear_7449_1013"
        x1="36.029"
        x2="36.029"
        y1="4.5"
        y2="67.687"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1ECECA"></stop>
        <stop offset="1" stopColor="#0B4571"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default IonLeaf;
