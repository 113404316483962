import React, { useContext, useEffect, useState } from 'react'
import { Footer, GooglePlayButton, HeaderB2B } from 'components'
import { Context } from 'helpers/language-provider'
import { FormattedMessage } from 'react-intl'
import { StarRating } from 'common'
import newsData from 'data/news.json'

import { ArrowRight, ElectrumB2B, ElectrumLogo, Thunder } from 'assets/icons'
import { AboutUs, BSS, BSSLocation, BatteryCharge, BatterySwap, EVBusiness, Excellent, ForBusiness, ForBusinessKV, ForBusinessKV2, ForBusinessSide, GetNowPlaceholder, HomeKV, HomeKV2, IntroH3, IntroH3New, IntroH3NewV2, Mileage, PBB, PSB, PackageBG, Placeholder1, Placeholder2, Placeholder3, PlaceholderRectangle1, ProvenQuality, Reviewer1, Reviewer2, Reviewer3, Riders, SaveCost, SepekanKV, SepekanKVBanner, SmartTech, SmartTechB2B, Swaps, TwoWheeler } from 'assets/images'
import styled from 'styled-components'
import Lottie from 'react-lottie'
import { HematSimulation, EcosystemText } from 'assets/lottie'
import Marquee from 'react-fast-marquee'
import { useNavigate } from 'react-router-dom'
import CostCalculator from 'components/cost-calculator-desktop'

// const banner1Placeholder = 'https://res.cloudinary.com/aldrichn/image/upload/v1713433322/Frame_490_r3sduk.png'
const banner1Placeholder = 'https://i.postimg.cc/fRNsGnYs/Frame-811270.png'
const banner2Placeholder = 'https://i.postimg.cc/9FXtJznH/Frame-505.png'
const asset1Placeholder = 'https://i.postimg.cc/vmx50j79/Frame-811279.png'
const asset2Placeholder = 'https://i.postimg.cc/5t8mmyML/Frame-710.png'
const designedPlaceholder = 'https://i.postimg.cc/JhGJhJX5/Frame-811279.png'

const HomeNew = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')
  const navigate = useNavigate()

  // BUTTON STYLES
  const btnStyleBlue = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#0B4571',
    border: 'none',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }
  const btnStyleWhite = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    border: 'none',
    color: '#0B4571',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
    width: 'fit-content',
  }
  const btnStyleTransparent = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: '3px solid white',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
    fontSize: '1rem',
  }

  // BANNER
  const [currentIndex, setCurrentIndex] = useState(0)
  const bannerList = [
    {
      banner_img: HomeKV,
      headline: 'Electrum H3i',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Daftar Test-ride',
      button1_link: '',
      button2_text: 'Explore H3i',
      button2_link: '',
    },
    {
      banner_img: HomeKV,
      headline: 'Electrum H3i',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Daftar Test-ride',
      button1_link: '',
      button2_text: 'Explore H3i',
      button2_link: '',
    },
    {
      banner_img: HomeKV,
      headline: 'Electrum H3i',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Daftar Test-ride',
      button1_link: '',
      button2_text: 'Explore H3i',
      button2_link: '',
    },
    {
      banner_img: HomeKV,
      headline: 'Electrum H3i',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Daftar Test-ride',
      button1_link: '',
      button2_text: 'Explore H3i',
      button2_link: '',
    },
    {
      banner_img: HomeKV,
      headline: 'Electrum H3i',
      subheadline: 'Bikin Perjalananmu Lebih Keren',
      button1_text: 'Daftar Test-ride',
      button1_link: '',
      button2_text: 'Explore H3i',
      button2_link: '',
    },
  ]
  const renderHomeCarousel = () => {
    return bannerList.map((item, index) => (
      <div key={index}>
        <div
          alt="Banner Placeholder"
          style={{
            backgroundImage: `url('${item.banner_img}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minWidth: '100vw',
            width: '100vw',
            height: '100%',
            scrollSnapAlign: 'start',
          }}
        >
          <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '6.5rem' }}>
            {/* HEADLINE & SUBHEADLINE */}
            <div style={{ fontFamily: 'Gilroy-Bold', color: 'white', fontSize: '4.475rem' }}>
              {item.headline}
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', color: 'white', fontSize: '2.5rem' }}>
              {item.subheadline}
            </div>
            {/* BUTTONS */}
            <div style={{ display: 'flex', columnGap: '1rem', marginTop: '1.5rem' }}>
              <button style={btnStyleBlue}>
                {item.button1_text} <ArrowRight fill="#fff" />
              </button>
              <button style={btnStyleTransparent}>
                {item.button2_text} <ArrowRight fill="#000" />
              </button>
            </div>
          </div>
        </div>
      </div>
    ))
  }
  const handleIndicatorClick = (index) => {
    setCurrentIndex(index)
    const carousel = document.getElementById('home-carousel')
    carousel.scrollTo({ left: index * window.innerWidth, behavior: 'smooth' })
  }
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     const nextIndex = (currentIndex + 1) % bannerList.length
  //     setCurrentIndex(nextIndex)
  //     const carousel = document.getElementById('home-carousel')
  //     carousel.scrollTo({ left: nextIndex * window.innerWidth, behavior: 'smooth' })
  //   }, 3500)
  //   return () => clearInterval(interval)
  // }, [currentIndex, bannerList.length])

  // TESTIMONY
  const testimoniesList = [
    {
      rate: 5,
      name: 'Rian Ernest',
      occupation: 'Politisi & Pengacara',
      picture: Reviewer1,
      review: '“Enak banget manuvernya, terutama pas macet. Torsinya terjaga dan bentuknya compact jadi enak banget.”',
    },
    {
      rate: 5,
      name: 'Putra Pratama Purwanto',
      occupation: 'Eksekutif Muda',
      picture: Reviewer2,
      review: '“Praktis banget buat swap & go. Tukar baterainya nggak lama. Terus, buat bayar energi juga simpel, bisa langsung transaksi di aplikasi.”',
    },
    {
      rate: 5,
      name: 'Alif Fajar',
      occupation: 'Pemerhati Otomotif 10+ tahun',
      picture: Reviewer3,
      review: '“Murah banget ketimbang bensin! Beli 5 Electrum Pulsa (EP) seharga 15rb aja bisa buat jarak hingga 120 km.”',
    },
    {
      rate: 5,
      name: 'Pak Madya',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684576/pak-madya_hhqdwl.png',
      review: '“Cocok banget, istri saya juga nyaman dibonceng. Makin manteb mau ambil H3i”',
    },
    {
      rate: 5,
      name: 'Vandy Kusumawardhana',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684576/vandy-kusumawardhana_lnetbx.png',
      review: '“Secara keseluruhan motornya enak, bagasi luas dan ternyata tukar baterai di BSS mudah dan cepat”',
    },
    {
      rate: 5,
      name: 'Rizky Anugrah Rafi',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684578/rizky-anugrah-rafi_x4zoal.png',
      review: '“Motornya enak untuk dalem kota. Buat macet-macet enak karena dimensinya cukup dan ringan. Jok enak buat berdua. Riding position enak”',
    },
    {
      rate: 5,
      name: 'Eky Nur Romadhon',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684569/eky-nur-romadhon_xr4pjf.png',
      review: '“Motornya nyaman dan irit sekali, 15ribu bisa buat 4x ganti batre. Bagasi dan deck luas, enak bisa buat bawa barang. Sendiri atau berdua nyaman”',
    },
    {
      rate: 5,
      name: 'Muhammad Iqbal Tawakal',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1731684574/muhammad-iqbal-tawakal_qmnwsg.png',
      review: '“Program sepekan sangat membantu bagi yang ingin merasakan experience  menggunakan motor listrik electrum dalam pengaplikasian sehari-hari apakah cocok untuk kebutuhan kita atau tidak.”',
    },
    {
      rate: 5,
      name: 'Dede Kurniawan',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1732085032/dede-kurniawan_splfjx.png',
      review: '"Enak dibawa dan enteng, untuk selap-selip enak apalagi di mode sport dan tidak mengecewakan saat dibawa"',
    },
    {
      rate: 5,
      name: 'Farouk Anoz',
      occupation: 'Pengguna Motor Listrik',
      picture: 'https://res.cloudinary.com/aldrichelectrum/image/upload/v1732085032/farouk-anoz_vdxgbm.png',
      review: '"Awalnya skeptis, tapi pas coba ternyata asyik juga. Dibawanya enak untuk harian dan pastinya berasa bgt hematnya dibanding BBM"',
    },
  ]
  const renderTestimonies = () => {
    return testimoniesList.map((item, index) => (
      <div
        key={index}
        style={{
          minWidth: '22.5rem',
          padding: '1.5rem',
          boxShadow: '0px 4px 20px 0px rgba(0,0,0,0.1)',
          borderRadius: '1.2rem',
          backgroundColor: 'white',
        }}
      >
        {/* TOP */}
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <img loading='lazy' src={item.picture} alt='user' width={60} style={{ borderRadius: '100rem' }} />
            <div style={{ marginLeft: '1rem', display: 'flex', flexDirection: 'column', rowGap: '0.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', color: '#103856', fontSize: '1.2rem' }}>
                {item.name}
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#103856' }}>
                {item.occupation}
              </div>
            <StarRating rate={5} />
            </div>
          </div>
        </div>
        {/* SEPARATOR */}
        <div style={{ borderBottom: '0.2rem #ECECEC solid', margin: '1.5rem 0' }} />
        {/* REVIEW */}
        <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#103856' }}>
          {item.review}
        </div>
      </div>
    ))
  }

  // SMART TECH
  const smartTechFeatsList = [
    {
      title: 'Sekali Tap Untuk Top Up',
      description: 'Lorem ipsum sit dolor amet',
    },
    {
      title: 'Mudahnya Cari BSS',
      description: 'Lorem ipsum sit dolor amet',
    },
    {
      title: 'Pantau Kondisi Motor',
      description: 'Lorem ipsum sit dolor amet',
    },
    {
      title: 'Selalu Terkoneksi',
      description: 'Lorem ipsum sit dolor amet',
    },
  ]
  const renderSmartTechFeats = () => {
    return smartTechFeatsList.map((item, index) => (
      <div>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#1ECECA' }}>
          {item.title}
        </div>
        {/* <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.125rem', color: '#103856' }}>
          {item.description}
        </div> */}
      </div>
    ))
  }

  // LATEST NEWS
  const renderLatestNews = () => {
    const indexes = [1, 0, 4]
    const newsDataArr = indexes.map(idx => newsData[idx])
    return newsDataArr.map((item, idx) => (
      <div key={idx}>
        <img
          loading='lazy'
          src={item.image}
          alt='placeholder'
          style={{
            width: '100%',
            height: '11.5rem',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '0.65rem 0.65rem 0 0',
          }}
        />
        <div style={{
          backgroundColor: 'white',
          padding: '1.25rem',
          borderRadius: '0 0 0.65rem 0.65rem',
        }}>
          <div className='two-lines' style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', color: '#103856' }}>
            {item.title}
          </div>
          <div className='three-lines' style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', marginTop: '0.75rem' }}>
            {item.description}
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '1.5rem' }}>
            <button
              onClick={() => navigate(`/news/detail/${item.slug}`)}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.5rem 1rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
            >
              See News <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }

  const ColoredText = styled.span`
    background: linear-gradient(to right, #158A9D, #1ECECA);
    color: transparent;
    background-clip: text;
  `

  return (
    <div style={{
      backgroundColor: '#F4F7FA',
    }}>
      <HeaderB2B />

      {/* BANNER CAROUSEL */}
      {/* <div>
        <div
          id="home-carousel"
          style={{
            width: '100vw',
            height: '90vh',
            margin: '0 auto',
            backgroundColor: 'white',
            display: 'flex',
            overflowX: 'scroll',
            scrollSnapType: 'x mandatory',
            position: 'relative',
          }}
          onScroll={(e) => {
            const scrollPosition = e.target.scrollLeft
            const newIndex = Math.round(scrollPosition / window.innerWidth)
            setCurrentIndex(newIndex)
          }}
        >
          {renderHomeCarousel()}
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '-3rem',
            position: 'relative',
            zIndex: '99',
          }}
        >
          {bannerList.map((slide, index) => (
            <div
              key={index}
              onClick={() => handleIndicatorClick(index)}
              style={{
                width: '45px',
                height: '6.5px',
                borderRadius: '50rem',
                backgroundColor: currentIndex === index ? '#1ECECA' : '#4F939D',
                margin: '0 5px',
                cursor: 'pointer',
              }}
            ></div>
          ))}
        </div>
      </div> */}

      {/* SINGLE BANNER */}
      <div
        alt="Banner Placeholder"
        style={{
          backgroundImage: `url('${ForBusinessKV2}')`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          minWidth: '100vw',
          width: '100vw',
          height: '90vh',
          scrollSnapAlign: 'start',
        }}
      >
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '6.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', color: 'white', fontSize: '4.475rem' }}>
            Electrum H3i
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', color: 'white', fontSize: '2.5rem' }}>
            Your Reliable Lifestyle Partner
          </div>
          <div style={{ display: 'flex', columnGap: '1rem', marginTop: '1.5rem' }}>
            <button
              style={btnStyleBlue}
              onClick={() => navigate('/get-yours')}
            >
              Dapatkan Sekarang <ArrowRight fill="#fff" />
            </button>
            <button
              style={btnStyleTransparent}
              onClick={() => navigate('/products/h3i')}
            >
              Explore H3i <ArrowRight fill="#000" />
            </button>
          </div>
        </div>
      </div>

      {/* B2B BANNER */}
      {/* <img alt='b2b-banner' src={ForBusinessKV2} style={{ width: '100%' }} /> */}

      {/* SEPEKAN BANNER */}
      <div style={{ padding: '0rem 12.5rem', margin: '8rem 0rem' }}>
        <img loading='lazy' 
          alt='sepekan-banner'
          src={SepekanKVBanner}
          onClick={() => navigate('/sepekan-form')}
          style={{ width: '100%', borderRadius: '1.5rem', cursor: 'pointer' }}
        />
      </div>

      {/* FOR BUSINESS */}
      {/* <div style={{
        padding: '12.5rem 12.5rem 0 12.5rem',
        backgroundImage: `url(${ForBusiness})`,
        backgroundSize: 'contain',
        maxWidth: '100vw',
        minHeight: '110vh',
        marginBottom: '-15rem',
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: 'white' }}>
              Electrum For <br/> Business
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Electrum for Business menawarkan opsi sewa <br /> atau beli kendaraan listrik roda dua yang <br /> fleksibel untuk operasional bisnis Anda sekaligus <br /> mendukung keberlanjutan lingkungan.
            </div>
            <button style={{ ...btnStyleWhite, marginTop: '1rem' }}>
              Lihat Selengkapnya <ArrowRight fill='#fff' />
            </button>
          </div>
          <img alt='forb2b-side' src={ForBusinessSide} style={{ width: '50%' }} />
        </div>
      </div> */}

      {/* EV BUSINESS */}
      <div>
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', color: '#103856', marginBottom: '1rem' }}>
            Your Reliable EV Fleet Solution
          </div>
          <ElectrumB2B height='90' />
          <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#71869C', marginTop: '2rem' }}>
            <b>Electrum for Business</b> menawarkan opsi sewa atau beli kendaraan listrik roda dua yang <br/> fleksibel untuk operasional bisnis Anda sekaligus mendukung keberlanjutan lingkungan.
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', margin: '3rem 0rem 1.5rem 0rem' }}>
            <button style={btnStyleBlue}>
              Lihat Selengkapnya <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
        <img alt='ev-business' src={EVBusiness} style={{ width: '100%' }} />
      </div>

      {/* INTRO H3i */}
      <div style={{ padding: '8.5rem 12.5rem 8.5rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856' }}>
          Perkenalkan, Electrum H3i
        </div>
        <div style={{ display: 'flex', justifyContent: 'start', marginTop: '2.5rem', marginBottom: '2.5rem' }}>
          <div style={{ backgroundColor: 'white', width: '100%', borderRadius: '1.125rem 0 0 1.125rem' }}>
            <img loading='lazy'  src={IntroH3NewV2} alt='about-us' style={{ width: '100%', height: '100%', borderRadius: '1.125rem', objectFit: 'cover', objectPosition: 'center' }} />
          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            rowGap: '1rem',
            padding: '2.75rem',
            backgroundColor: 'white',
            borderRadius: '0rem 1.125rem 1.125rem 0rem',
          }}>
            <div>
              <Marquee
                speed={35}
                style={{
                  width: '35%',
                  fontFamily: 'Gilroy-SemiBold',
                  fontSize: '1.125rem',
                  color: 'white',
                  padding: '0.75rem 1.5rem',
                  borderRadius: '0.4rem',
                  background: 'linear-gradient(to right, #1ECECA, #1AABB4)',
                  marginBottom: '1.5rem',
                }}
              >
                <span style={{ marginRight: '1rem' }}>
                  Motor Baru dari Electrum &nbsp; •
                </span>
              </Marquee>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', marginBottom: '0.75rem' }}>
                Electrum H3i
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Dengan 7 warna ekspresif, Electrum H3i kini hadir sebagai partner gaya hidup urban tanpa emisi.
              </div>
            </div>
            <div>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', marginBottom: '0.5rem' }}>
                <ColoredText>
                  Harga mulai Rp 19,900,000
                </ColoredText>
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1.125rem', color: '#71869C', marginBottom: '1rem' }}>
                Atau cicilan Rp. 500 ribuan/bulan
              </div>
              <div style={{ display: 'flex', columnGap: '1.5rem' }}>
                <button
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#0B4571',
                    border: 'none',
                    color: 'white',
                    fontFamily: 'Gilroy-SemiBold',
                    padding: '0.75rem 1.5rem',
                    borderRadius: '0.5rem',
                    fontSize: '1rem',
                  }}
                  onClick={() => navigate('/get-yours')}
                >
                  Pesan Sekarang <ArrowRight fill="#fff" />
                </button>
                <button
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: 'white',
                    border: '2.5px solid #0B4571',
                    color: '#0B4571',
                    fontFamily: 'Gilroy-SemiBold',
                    padding: '0.75rem 1.5rem',
                    borderRadius: '0.5rem',
                    fontSize: '1rem',
                  }}
                  onClick={() => navigate('/products/h3i')}
                >
                  Pelajari H3i <ArrowRight fill="#fff" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ABOUT US */}
      <div style={{ paddingBottom: '8.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856', marginLeft: '12.5rem' }}>
          Tentang Electrum
        </div>
        <div style={{ display: 'flex', justifyContent: 'start', marginTop: '2.5rem', marginBottom: '2.5rem', marginRight: '12.5rem' }}>
          <img loading='lazy'  src={AboutUs} alt='about-us' style={{ width: '65%', minHeight: '100%', backgroundColor: 'white', objectFit: 'cover', objectPosition: 'center' }} />
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            rowGap: '1rem',
            padding: '2.5rem',
            backgroundColor: 'white',
            borderRadius: '0 1.125rem 1.125rem 0',
          }}>
            <div>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', marginBottom: '1rem' }}>
                Teruji untuk Masa Depan Hijau
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.9rem', color: '#71869C' }}>
                Electrum tercipta melalui kolaborasi, dengan kesadaran bahwa kemajuan kami dibangun atas masukan dan dedikasi kolektif dari semua pihak yang terlibat.
                <br /><br />
                Dengan rekam jejak yang teruji, Electrum menghadirkan kendaraan listrik roda dua yang efisien, tangguh, dan ramah lingkungan.
                <br /><br />
                Kami secara aktif mencari masukan dari pengguna dan mitra, menjadikannya dasar untuk terus berinovasi. Pendekatan berbasis komunitas ini memastikan bahwa kami tidak hanya menciptakan solusi mobilitas listrik, tetapi juga mendorong gerakan menuju hidup berkelanjutan.
                <br /><br />
                Bersama, kita dapat menciptakan perubahan yang bermakna dan mewujudkan masa depan yang lebih bersih dan hijau untuk semua.
              </div>
            </div>
            <div>
              <button
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#0B4571',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'Gilroy-SemiBold',
                  padding: '0.75rem 1.5rem',
                  borderRadius: '0.5rem',
                  fontSize: '1rem',
                }}
                onClick={() => navigate('/about-us')}
              >
                See More <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* TRUST */}
      <div style={{ padding: '0rem 12.5rem 0rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856', textAlign: 'center' }}>
          Terbukti dan Terpercaya
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.25rem', color: '#71869C', textAlign: 'center', marginTop: '1.5rem' }}>
          Dengan rekam jejak yang teruji, Electrum menghadirkan kendaraan listrik roda dua yang efisien, tangguh, dan ramah lingkungan.
        </div>
        <img loading='lazy'  src={ProvenQuality} alt='proven-quality' style={{ width: '100%', marginTop: '1.5rem' }} />
      </div>

      {/* TWO WHEELER */}
      <div style={{ backgroundColor: 'white', borderRadius: '1.125rem' }}>
        <div style={{ padding: '7.5rem 12.5rem', display: 'flex', justifyContent: 'center', columnGap: '2.5rem', flexWrap: 'wrap' }}>
          <img loading='lazy'  alt='mileage' src={Mileage} style={{ maxWidth: 'calc(33% - 1.75rem)', flex: '1 1 auto' }} />
          <img loading='lazy'  alt='riders' src={Riders} style={{ maxWidth: 'calc(33% - 1.75rem)', flex: '1 1 auto' }} />
          <img loading='lazy'  alt='swaps' src={Swaps} style={{ maxWidth: 'calc(33% - 1.75rem)', flex: '1 1 auto' }} />
        </div>
        {/* <div style={{ backgroundColor: 'white', borderRadius: '2.5rem' }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: EcosystemText,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            style={{
              width: '100%',
              height: '300px',
            }}
          />
        </div> */}
      </div>

      {/* CHARGE/SWAP */}
      <div style={{ padding: '10rem 12.5rem 8rem 12.5rem' }}>
        {/* TITLE SECTION */}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856' }}>
            Charge Atau Swap? <br />
            Bisa Dua-duanya!
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '45%', marginTop: '0.5rem' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Fleksibel mengisi daya baterai untuk mobilitas Anda. <br />
              Charge di rumah, swap di jalan, atau kombinasi keduanya.
            </div>
            <div>
              <button
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#0B4571',
                  border: 'none',
                  color: 'white',
                  fontFamily: 'Gilroy-SemiBold',
                  padding: '0.75rem 1.5rem',
                  borderRadius: '0.5rem',
                  fontSize: '1rem',
                }}
                onClick={() => navigate('/energy')}
              >
                Tunjukkan Saya <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
        </div>
        {/* CARDS */}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2.5rem', marginTop: '2.5rem' }}>
          {/* CHARGE */}
          <div>
            <img loading='lazy'  src={BatteryCharge} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: 'white', padding: '4rem 2.5rem 3rem 2.5rem', borderRadius: '1.25rem', minHeight: '7rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.8rem', marginBottom: '1rem' }}>
                Charge
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Charge di rumah atau di mana pun hingga penuh dalam waktu 2,5 jam (840 watt) atau 6,5 jam (340 watt).
              </div>
            </div>
          </div>
          {/* SWAP */}
          <div>
            <img loading='lazy'  src={BatterySwap} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: 'white', padding: '4rem 2.5rem 3rem 2.5rem', borderRadius: '1.25rem', minHeight: '7rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.8rem', marginBottom: '1rem' }}>
                Swap
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Hanya butuh hitungan detik untuk swap baterai di Battery Swap Station (BSS) dan gas lagi perjalananmu.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* BSS LOCATION */}
      <div style={{ padding: '0rem 12.5rem 3.5rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856', textAlign: 'center' }}>
          {/* Kiri-Kanan Kulihat Saja <br />
          Ada BSS Dimana-mana */}
          250+ BSS (Battery Swap Station) <br />
          di Jadetabek
        </div>
        <div style={{ margin: '2rem 0rem', display: 'flex', justifyContent: 'center' }}>
          <img loading='lazy'  alt='bss' src={BSS} style={{ width: '100%' }} />
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          {/* <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.5rem', color: '#103856' }}>
            250+ BSS di Jadetabek
          </div> */}
          <div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', marginBottom: '1.5rem' }}>
              Ratusan Battery Swap Station (BSS) yang tersebar di seluruh Jadetabek siap layani kamu untuk tukar baterai dalam hitungan detik. Super sat-set!
            </div>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => window.open('https://bit.ly/bsselectrum', '_blank')}
            >
              Cek Lokasi BSS <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>

      {/* PACKAGE */}
      {/* <div style={{ padding: '3rem 0rem' }}>
        <div style={{
          backgroundImage: `url('${PackageBG}')`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          padding: '5.5rem 12.5rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '3.5rem', color: '#103856', textAlign: 'center', marginBottom: '3.25rem' }}>
            Opsi Pembelian Electrum H3i
          </div>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2.25rem' }}>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
              <img loading='lazy'  alt='PBB' src={PBB} style={{ width: '100%' }} />
              <div style={{
                padding: '1.25rem 2rem',
                backgroundColor: 'white',
                borderRadius: '0.85rem',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px',
                minHeight: '19rem',
              }}>
                <div style={{ fontFamily: 'Gilroy-Medium', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                  <ul>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div>Garansi baterai 5 tahun/50 ribu km</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Extended Warranty part hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Extended Warranty dinamo hingga 30 ribu km/3 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Home Service 1 tahun</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Emergency Road Assistance 1 tahun</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
              <img loading='lazy'  alt='PSB' src={PSB} style={{ width: '100%' }} />
              <div style={{
                padding: '1.25rem 2rem',
                backgroundColor: 'white',
                borderRadius: '0.85rem',
                boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px',
                minHeight: '19rem',
              }}>
                <div style={{ fontFamily: 'Gilroy-Medium', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                  <ul>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> sewa baterai selama 12 bulan</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Warranty part hingga 10 ribu km/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Warranty dinamo hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                    </li>
                    <li style={{ marginBottom: '0.85rem' }}>
                      <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* SAVE COST */}
      {/* <div style={{ padding: '8rem 0rem 3rem 0rem', display: 'flex', columnGap: '2.5rem' }}>
        <div style={{ padding: '3rem 6rem 3rem 12.5rem', backgroundColor: 'white', borderRadius: '0 2.5rem 2.5rem 0', width: 'fit-content' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856', marginBottom: '1.5rem' }}>
            Hemat Banyak <br />
            <ColoredText>Untung Banyak</ColoredText>
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.5rem', color: '#71869C', marginBottom: '7.5rem' }}>
            Biaya Bahan Bakar Lebih <br />
            Efisien Hingga {' '}
            <span style={{ fontFamily: 'Gilroy-Bold', color: '#1ECECA' }}>
              40%
            </span>
          </div>
          <button style={{
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: '#0B4571',
            border: 'none',
            color: 'white',
            fontFamily: 'Gilroy-SemiBold',
            padding: '0.75rem 1.5rem',
            borderRadius: '0.5rem',
            fontSize: '1rem',
          }}>
            Cek Hematnya <ArrowRight fill="#fff" />
          </button>
        </div>
        <div style={{ backgroundColor: 'white', borderRadius: '2.5rem' }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: HematSimulation,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice"
              }
            }}
            height={500}
            width={500}
            style={{
              borderRadius: '2.5rem',
            }}
          />
        </div>
      </div> */}

      {/* COST CALCULATOR */}
      {/* <div style={{ padding: '8rem 0rem 3rem 0rem' }}>
        <CostCalculator />
      </div> */}

      {/* CHARGE/SWAP */}
      {/* <div style={{ padding: '8rem 12.5rem' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856' }}>
            Charge Atau Swap? <br />
            Suka-suka Kamu
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '45%' }}>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C' }}>
              Lebih nyaman nge-charge baterai di rumah? Bisa.
              Atau perlu nge-swap di jalan? Juga bisa.
              Bebas pilih yang kamu suka.
            </div>
            <div>
              <button style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}>
                Tell Me More <ArrowRight fill="#fff" />
              </button>
            </div>
          </div>
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '2.5rem', marginTop: '2.5rem' }}>
          <div>
            <img loading='lazy'  src={BatteryCharge} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: 'white', padding: '4rem 2.5rem 3rem 2.5rem', borderRadius: '1.25rem', minHeight: '7rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.8rem', marginBottom: '1rem' }}>
                Charge
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Charge di rumah atau di mana pun senyamannya kamu, dengan kapasitas charger mulai dari 340 watt.
              </div>
            </div>
          </div>
          <div>
            <img loading='lazy'  src={BatterySwap} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: 'white', padding: '4rem 2.5rem 3rem 2.5rem', borderRadius: '1.25rem', minHeight: '7rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.8rem', marginBottom: '1rem' }}>
                Swap
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '1rem', color: '#71869C' }}>
                Jangan sampai mati gaya gara-gara habis daya! Tinggal swap baterai kamu di Battery Swap Station (BSS) dan gas lagi perjalananmu.
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* SMART TECHNOLOGY */}
      <div style={{ padding: '8rem 0rem 8rem 0rem' }}>
        <img loading='lazy'  alt='bss-location' src={BSSLocation} style={{ width: '100%' }} />
        <div style={{
          margin: '-3rem 12.5rem 0rem 12.5rem',
          position: 'relative',
          backgroundColor: 'white',
          borderRadius: '2rem',
        }}>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
            <img loading='lazy'  alt='smart-tech' src={SmartTechB2B} style={{ width: '100%', borderRadius: '2rem 0 0 2rem' }} />
            <div style={{ padding: '3.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.5rem', color: '#103856', marginBottom: '2.5rem' }}>
                Teknologi yang Smart
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
                {renderSmartTechFeats()}
                <GooglePlayButton />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TESTIMONY */}
      {/* <div style={{ padding: '6rem 0rem 6rem 0rem', backgroundColor: 'white', borderRadius: '2.5rem 0 0 2.5rem', marginLeft: '6rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.75rem', color: '#103856', paddingLeft: '6.5rem' }}>
          <FormattedMessage
            id="testimony"
            defaultMessage={defaults["testimony"]}
          />
        </div>
        <div style={{
          padding: '1rem 6.5rem',
          marginTop: '3rem',
          display: 'flex',
          overflowX: 'scroll',
          columnGap: '2rem',
          scrollPaddingLeft: '6rem',
        }}>
          {renderTestimonies()}
        </div>
      </div> */}

      {/* GET NOW */}
      {/* <div style={{ padding: '8rem 0rem 6.5rem 0rem' }}>
        <div style={{ width: '85%', position: 'relative' }}>
          <img loading='lazy'  alt='getnow-placeholder' src={GetNowPlaceholder} style={{ width: '100%' }} />
          <div style={{
            width: '35%',
            padding: '3.5rem 5rem 3.5rem 8.5rem',
            backgroundColor: 'white',
            borderRadius: '0 1.125rem 1.125rem 0',
            position: 'absolute',
            top: '80%',
            left: '0',
            transform: 'translateY(-80%)',
          }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.875rem', marginBottom: '1rem' }}>
              Dapatkan Sekarang!
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', marginBottom: '3.5rem' }}>
              Tersedia pilihan paket Electrum yang menarik melalui variasi metode pembayaran yang kamu inginkan.
            </div>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
              }}
              onClick={() => navigate('/get-yours')}
            >
              Dapatkan H3i <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div> */}

      {/* DRIVER RENT */}
      {/* <div style={{ margin: '0 12.5rem 6rem 12.5rem', padding: '3rem', backgroundColor: 'white', borderRadius: '1.875rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2.25rem', marginBottom: '1rem' }}>
          Sewa Khusus Mitra Driver
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end' }}>
          <div style={{ fontFamily: 'Gilroy-Semibold', color: '#71869C', maxWidth: '40%' }}>
            Dapatkan penawaran khusus untuk mitra driver ojek online melalui skema penyewaan bulanan motor listrik Electrum.
          </div>
          <div>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => window.open('https://opsapp.id/registrasi_electrum', '_blank')}
            >
              Sewa Electrum <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div> */}

      {/* LATEST NEWS */}
      <div style={{ padding: '2rem 12.5rem 10rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.375rem', color: '#103856', marginBottom: '2rem' }}>
          Berita Terkini
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', columnGap: '2rem', rowGap: '2rem', marginBottom: '1.5rem' }}>
          {renderLatestNews()}
        </div>
        <div style={{ display: 'flex', justifyContent: 'start' }}>
          <button
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
              width: 'fit-content',
            }}
            onClick={() => navigate('/news')}
          >
            See More <ArrowRight fill="#fff" />
          </button>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default HomeNew
