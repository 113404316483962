import { ArrowRight } from 'assets/icons'
import { AboutTD1, AboutTD2, AboutTD3, AboutTD4, BatteryCharge, BatterySwap, ColorAmber, ColorBeige, ColorDerawanBlue, ColorGrey, ColorIceNavy, ColorIvoryWhite, ColorRedCherry, ColorViridianGreen, GetNowPlaceholderMobile, IntroProduct, IntroProductMobile, MickeyPortrait, MickeySquare, MobileKV, PBB, PSB, ProductKVMobile, TechDesign1, TechDesign2, TechDesign3, Understand1, Understand2, Understand3, Understand4, Understand5, Understand6 } from 'assets/images'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const bannerPlaceholder = 'https://i.postimg.cc/fRNsGnYs/Frame-811270.png'

const H3 = () => {
  const navigate = useNavigate()

  // KV CAROUSEL
  const [currentIndex, setCurrentIndex] = useState(0)
  const KVList = [
    { img: ProductKVMobile },
    { img: ProductKVMobile },
    { img: ProductKVMobile },
  ]
  const renderKVCarousel = () => {
    return KVList.map((item, index) => (
      <div
        key={index}
        style={{
          scrollSnapAlign: 'start',
          backgroundSize: 'cover',
          height: '100%',
          maxWidth: '100%', 
          flexShrink: 0,
          position: 'relative',
        }}
      >
        <img
          effect='blur'
          alt='top-banner'
          src={item.img}
          loading='lazy'
          style={{
            height: '100%',
            width: '100%', 
            objectFit: 'cover',
          }}
        />
        <div style={{
          width: '100%',
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          color: 'white',
          padding: '10px 20px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: '#103856', marginBottom: '0.4rem' }}>
            Electrum H1
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#103856', marginBottom: '1rem' }}>
            Bikin Perjalananmu Lebih Keren
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', columnGap: '1rem' }}>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              See More <ArrowRight fill="#fff" />
            </button>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'transparent',
              border: 'none',
              color: '#0B4571',
              border: '3px solid #0B4571',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              Get Yours <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>
    ))
  }
  const handleIndicatorClick = (index) => {
    setCurrentIndex(index)
    const carousel = document.getElementById('kv-carousel')
    carousel.scrollTo({ left: index * carousel.clientWidth, behavior: 'smooth' })
  }
  const handleScrollCarousel = (direction) => {
    const container = document.getElementById('kv-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'smooth',
    })
  }

  // COLOR CAROUSEL
  const [currentColorIndex, setColorCurrentIndex] = useState(0)
  const ColorList = [
    { img: ColorIvoryWhite, color: 'Ivory White', palette: '#e5e5e5' },
    // { img: ColorRedCherry, color: 'Red Cherry', palette: '#f04c41' },
    // { img: ColorAmber, color: 'Amber', palette: '#f49f25' },
    { img: ColorIceNavy, color: 'Ice Navy', palette: '#a7d3dd' },
    // { img: ColorViridianGreen, color: 'Viridian Green', palette: '#3f7772' },
    { img: ColorBeige, color: 'Beige', palette: '#d5cdc2' },
    { img: ColorGrey, color: 'Grey', palette: '#808388' },
  ]
  const renderColorCarousel = () => {
    return ColorList.map((item, index) => (
      <div key={index}>
        <img loading='lazy'  alt={item.color} src={item.img} style={{ width: '100vw', marginBottom: '3rem', scrollSnapAlign: 'start' }} />
      </div>
    ))
  }
  const renderColorBar = () => {
    return ColorList.map((item, index) => (
      <div style={currentColorIndex === index ? {
        display: 'inline-block',
        padding: '0.2rem',
        boxShadow: `0px 0px 10px 0px ${item.palette}`,
        borderRadius: '20rem',
      } : {
        padding: '0rem',
        boxShadow: 'none',
      }}>
        <div
          style={{ cursor: 'pointer', width: '2.25rem', height: '0.5rem', borderRadius: '20rem', backgroundColor: item.palette }}
          onClick={() => handleColorCarouselClick(index)}
        />
      </div>
    ))
  }
  const handleScrollColorCarousel = (direction) => {
    const container = document.getElementById('color-carousel')
    const containerWidth = container.clientWidth
    container.scrollTo({
      left: container.scrollLeft + direction * containerWidth,
      behavior: 'instant',
    })
  }
  const handleColorCarouselClick = (index) => {
    setColorCurrentIndex(index)
    const carousel = document.getElementById('color-carousel')
    carousel.scrollTo({ left: index * window.innerWidth, behavior: 'instant' })
  }

  // TECH DESIGN 2
  const gridItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1rem',
  }
  const imageStyle = {
    width: '100%',
  }
  const headerStyle = {
    fontFamily: 'Gilroy-Bold',
    fontSize: '1rem',
    color: '#103856',
  }
  const subheaderStyle = {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: '0.75rem',
    color: '#71869C',
    marginTop: '-0.25rem',
  }
  const tdList = [
    {
      img: AboutTD1,
      header: 'Bagasi Luas',
      subheader: 'Nggak ada yang bisa ngalahin luasnya bagasi motor listrik Electrum H1. Mulai dari tas, topi, sepatu, jas hujan, sampai helm full face, semua muat!',
    },
    {
      img: AboutTD2,
      header: 'Desain Modern',
      subheader: 'Dibawa ngantor, morning ride, atau hangout, Electrum H1 bakal jadi fashion statement favorit kamu.',
    },
    {
      img: AboutTD3,
      header: 'Ruang Lega',
      subheader: 'Area motornya yang lega, mulai dari ruang kaki sampai jok, bikin sensasi berkendara lebih nyaman.',
    },
    {
      img: AboutTD4,
      header: 'Lincah & Ringan',
      subheader: 'Bobotnya yang ringan bikin Electrum H1 jadi paling sat-set dan lincah di jalan. Jalanan berbelok-belok? Nggak masalah!',
    },
  ]
  const renderTD = () => {
    return tdList.map((item, index) => (
      <div ley={index} style={gridItemStyle}>
        <img loading='lazy'  alt={`td${index+1}`} src={item.img} style={imageStyle} />
        <div style={headerStyle}>{item.header}</div>
        <div style={subheaderStyle}>{item.subheader}</div>
      </div>
    ))
  }

  // COLORED TEXT
  const ColoredText = styled.span`
    background: linear-gradient(0deg, #0B4571, #1ECECA);
    color: transparent;
    background-clip: text;
    font-family: Gilroy-Bold;
    font-size: 1rem;
    text-align: center;
  `

  // UNDERSTAND
  const understandArr = [
    {
      img: Understand1,
      title: 'Baterai Tahan Lama',
      description: 'Berbekal 1 buah baterai NCM yang tahan jarak tempuh hingga 65 km, riding ke tempat hangout after-office lebih dari cukup.',
    },
    {
      img: Understand2,
      title: '2 Mode Unggulan',
      description: '<b>Mode Sport</b> siap menjawab kebutuhan akselerasi dan kecepatan, sedangkan <b>Mode Smart</b> bantu kamu hemat energi tanpa mengurangi performa.',
    },
    {
      img: Understand3,
      title: 'Cruise Control & Reverse Assist',
      description: 'Fitur <b>Cruise Control</b> memungkinkan kamu aman berkendara dengan kecepatan konstan. Butuh bantuan mundur? Serahkan pada fitur <b>Reverse Assist</b>.',
    },
    {
      img: Understand4,
      title: 'Smart Key Remote',
      description: 'Dengan <b>Smart Key Remote</b>, kamu bisa menyalakan/mematikan motor, tanda alarm, hingga membuka jok. Semua kebutuhan dalam satu genggaman.',
    },
    {
      img: Understand5,
      title: 'Adaptive Speedometer',
      description: 'Tidak hanya menampilkan informasi performa motor dan baterai, warna panel speedometer dapat berubah secara adaptif mengikuti terang-gelapnya kondisi sekeliling saat berkendara.',
    },
    {
      img: Understand6,
      title: 'Pengisi daya USB',
      description: 'Nggak ada lagi cerita handphone-mu lowbat. Charge aja langsung lewat USB port di motor listrik Electrum kamu. Anti lowbat-lowbat club!',
    },
  ]
  const renderUnderstand = () => {
    return understandArr.map((item, index) => (
      <div key={index} style={{ minWidth: '17.5rem' }}>
        <img loading='lazy'  alt='understand' src={item.img} style={{ width: '100%', height: 'auto', objectFit: 'cover' }} />
        <div style={{ backgroundColor: 'white', borderRadius: '0 0 0.625rem 0.625rem', padding: '2rem 1.5rem 1.5rem 1.5rem', minHeight: '10.5rem', marginTop: '-0.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', color: '#103856', marginBottom: '0.65rem' }}>
            {item.title}
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C' }}>
            {item.description}
          </div>
        </div>
      </div>
    ))
  }

  return (
    <div>

      {/* KV CAROUSEL */}
      {/* <div style={{ marginBottom: '4rem' }}>
        <div style={{ position: 'relative', width: '100%' }}>
          <div
            id="kv-carousel"
            style={{
              margin: '0 auto',
              display: 'flex',
              overflowX: 'scroll',
              scrollSnapType: 'x mandatory',
              scrollSnapStop: 'always',
            }}
            onScroll={(e) => {
              const container = e.target
              const scrollPosition = container.scrollLeft
              const containerWidth = container.clientWidth
              const newIndex = Math.round(scrollPosition / containerWidth)
              setCurrentIndex(newIndex)
            }}
          >
            {renderKVCarousel()}
            <button
              onClick={() => handleScrollCarousel(-1)}
              style={{
                position: 'absolute',
                top: '50%',
                left: '15px',
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                color: 'black',
                border: 'none',
                borderRadius: '50%',
                padding: '1px 11.5px 2.5px 11.5px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                display: currentIndex === 0 ? 'none' : 'block',
              }}
            >
              &#8249;
            </button>
            <button
              onClick={() => handleScrollCarousel(1)}
              style={{
                position: 'absolute',
                top: '50%',
                right: '15px',
                transform: 'translateY(-50%)',
                backgroundColor: 'rgba(255, 255, 255, 0.6)',
                color: 'black',
                border: 'none',
                borderRadius: '50%',
                padding: '1px 11.5px 2.5px 11.5px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                display: currentIndex === KVList.length-1 ? 'none' : 'block',
              }}
            >
              &#8250;
            </button>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1rem',
            position: 'relative',
          }}
        >
          {KVList.map((item, index) => (
            <div
              key={index}
              onClick={() => handleIndicatorClick(index)}
              style={{
                width: '30px',
                height: '6.5px',
                borderRadius: '50rem',
                backgroundColor: currentIndex === index ? '#1ECECA' : '#4F939D',
                margin: '0 5px',
                cursor: 'pointer',
              }}
            ></div>
          ))}
        </div>
      </div> */}

      {/* SINGLE BANNER */}
      <div
        style={{
          scrollSnapAlign: 'start',
          backgroundSize: 'cover',
          height: '100%',
          maxWidth: '100%', 
          flexShrink: 0,
          position: 'relative',
          marginBottom: '3rem',
        }}
      >
        <img
          effect='blur'
          alt='top-banner'
          src={ProductKVMobile}
          loading='lazy'
          style={{
            height: '100%',
            width: '100%', 
            objectFit: 'cover',
          }}
        />
        <div style={{
          width: '100%',
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, -10%)',
          color: 'white',
          padding: '10px 0px',
          borderRadius: '5px',
          textAlign: 'center',
        }}>
          <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '2rem', color: '#103856', marginBottom: '0.4rem' }}>
            Electrum H1
          </div>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#103856', marginBottom: '1rem' }}>
            Bikin Perjalananmu Lebih Keren
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', rowGap: '1rem' }}>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => navigate('/get-yours')}
            >
              Dapatkan Sekarang <ArrowRight fill="#fff" />
            </button>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'transparent',
                border: '3px solid #0B4571',
                color: '#0B4571',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.55rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
              }}
              onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
            >
              Daftar Test-ride <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>

      {/* INTRO */}
      <div style={{ padding: '3rem 2rem 2rem 2rem', backgroundColor: 'white', borderRadius: '1.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', paddingLeft: '2rem', marginBottom: '0.25rem' }}>
          Partner Lifestyle Kamu
        </div>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1rem', color: '#103856', paddingLeft: '2rem', marginBottom: '1rem' }}>
          Gaya Tanpa Polusi
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.85rem', color: '#71869C', paddingLeft: '2rem', marginBottom: '3.5rem', borderLeft: '0.25rem solid #1ECECA' }}>
          Electrum H1 adalah partner lifestyle buat kamu morning ride, ngafe, olahraga, sampai berburu dessert viral. Desainnya yang stylish bikin kamu jadi pusat perhatian ke mana pun kamu pergi. Dengan Electrum H1, kamu bisa menjelajahi kota dengan gaya tanpa polusi. Yuk, jadi bagian dari komunitas Electrum sebagai Gen-E!
        </div>
        <img loading='lazy'  alt='rectangle-placeholder' src={IntroProductMobile} style={{ width: '100%' }} />
      </div>

      {/* VIDEO */}
      <div style={{ padding: '4rem 0' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', textAlign: 'center', marginBottom: '1.5rem', padding: '0 2rem' }}>
          Electrum H1 <br />
          Teman Aktivitas Sehari-hari
        </div>
        <video
          // src={H1Video}
          // src='https://res.cloudinary.com/aldrichelectrum/video/upload/v1729845391/IMG_5908_tfa9g3.mp4'
          src='https://res.cloudinary.com/aldrichelectrum/video/upload/v1729581547/1021_k6adzt.mp4'
          autoPlay
          muted
          loop
          style={{
            width: '100%',
            transition: 'width 0.5s ease-in-out',
          }}
        />
      </div>

      {/* ABOUT TECH DESIGN 2 */}
      <div style={{
        backgroundColor: 'white',
        padding: '3rem 2rem',
        borderRadius: '1.5rem',
        marginBottom: '4rem',
      }}>
        <div style={{
          fontFamily: 'Gilroy-ExtraBold',
          fontSize: '1.5rem',
          color: '#103856',
          marginBottom: '2rem',
        }}>
          Desain Stylish <br />
          & Ergonomis
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '3rem' }}>
          {renderTD()}
        </div>
      </div>

      {/* UNDERSTAND */}
      <div style={{ padding: '3rem 2rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.75rem', color: '#103856', textAlign: 'center', marginBottom: '2.5rem' }}>
          Teknologi yang <br />
          Mengerti Kamu
        </div>
        <div style={{ display: 'flex', columnGap: '1.25rem', overflow: 'scroll' }}>
          {renderUnderstand()}
        </div>
      </div>

      {/* ABOUT TECH DESIGN 1 */}
      <div style={{ padding: '0rem 2rem 4rem 2rem' }}>
        <div style={{
          fontFamily: 'Gilroy-ExtraBold',
          fontSize: '1.5rem',
          color: '#103856',
          textAlign: 'center',
          marginBottom: '2rem',
        }}>
          Efisien Adalah Kunci
        </div>
        {/* CARD 1 */}
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '2rem' }}>
          <img loading='lazy'  alt='techdesign1' src={TechDesign1} style={{ width: '100%', backgroundColor: 'white', borderRadius: '0 1.5rem 0 0' }} />
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            rowGap: '1rem',
            borderRadius: '0 0 1.5rem 1.5rem',
            backgroundColor: '#F4F7FA',
            padding: '1.5rem',
            backgroundColor: 'white',
          }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.125rem', color: '#103856' }}>
              Hemat Biaya Bahan <br />
              Bakar Hingga 60%
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C' }}>
              Cuma butuh 5 ribuan untuk sekali isi energi. Yes, kamu nggak salah lihat. Memang semurah itu biayanya untuk jarak hingga 65 km!
            </div>
          </div>
        </div>
        {/* CARD 2 */}
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '2rem' }}>
          <img loading='lazy'  alt='techdesign1' src={TechDesign2} style={{ width: '100%', backgroundColor: 'white', borderRadius: '0 1.5rem 0 0' }} />
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            rowGap: '1rem',
            borderRadius: '0 0 1.5rem 1.5rem',
            backgroundColor: '#F4F7FA',
            padding: '1.5rem',
            backgroundColor: 'white',
          }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.125rem', color: '#103856' }}>
              Minim Perawatan
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C' }}>
              Nggak ada lagi tuh, pengeluaran buat ganti oli, aki, dan lainnya. Lumayan, uangnya bisa kamu tabung buat nge-date, kan.
            </div>
          </div>
        </div>
        {/* CARD 3 */}
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <img loading='lazy'  alt='techdesign1' src={TechDesign3} style={{ width: '100%', backgroundColor: 'white', borderRadius: '0 1.5rem 0 0' }} />
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            rowGap: '1rem',
            borderRadius: '0 0 1.5rem 1.5rem',
            backgroundColor: '#F4F7FA',
            padding: '1.5rem',
            backgroundColor: 'white',
          }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.125rem', color: '#103856' }}>
              Hemat Waktu
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C' }}>
              Hemat waktumu dengan fast charger yang siap isi ulang baterai dalam 3 jam, atau swap baterai dalam hitungan detik di ratusan BSS di Jadetabek.
            </div>
          </div>
        </div>
      </div>

      {/* CHARGE/SWAP */}
      <div style={{ padding: '0 2rem 4rem 2rem' }}>
        {/* TITLE SECTION */}
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', marginBottom: '0.75rem' }}>
          Charge Atau Swap? <br />
          Bisa Dua-duanya!
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.75rem', marginBottom: '2.5rem' }}>
          <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C' }}>
            Lebih nyaman nge-charge baterai di rumah? Bisa.
            Atau perlu nge-swap di jalan? Juga bisa.
            Bebas pilih yang kamu suka.
          </div>
        </div>
        {/* CARDS */}
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
          {/* CHARGE */}
          <div>
            <img loading='lazy'  src={BatteryCharge} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#F4F7FA', padding: '3.25rem 2rem 2rem 2rem', borderRadius: '0.75rem', backgroundColor: 'white' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', marginBottom: '0.5rem' }}>
                Charge
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem', color: '#71869C' }}>
                Charge di rumah atau di mana pun senyamannya kamu, dengan kapasitas charger mulai dari 340 watt.
              </div>
            </div>
          </div>
          {/* SWAP */}
          <div>
            <img loading='lazy'  src={BatterySwap} alt='asset2Placeholder' style={{ width: '100%', marginBottom: '-1.75rem' }} />
            <div style={{ backgroundColor: '#F4F7FA', padding: '3.25rem 2rem 2rem 2rem', borderRadius: '0.75rem', backgroundColor: 'white' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem', marginBottom: '0.5rem' }}>
                Swap
              </div>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.75rem', color: '#71869C' }}>
                Jangan sampai mati gaya gara-gara habis daya! Tinggal swap baterai kamu di Battery Swap Station (BSS) dan gas lagi perjalananmu.
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: '1.5rem' }}>
          <button
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}
            onClick={() => navigate('/energy')}
          >
            Tunjukkan Saya <ArrowRight fill="#fff" />
          </button>
        </div>
      </div>

      {/* PACKAGE */}
      <div style={{ padding: '3rem 2rem', backgroundColor: 'white', borderRadius: '1.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', color: '#103856', textAlign: 'center', marginBottom: '1.25rem' }}>
          Pilihan Paket
        </div>
        <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: '#71869C', textAlign: 'center', marginBottom: '2.25rem' }}>
          Tersedia 2 pilihan paket kepemilikan baterai, yang tidak mempengaruhi fleksibilitas cara kamu mengisi ulang energi (charge maupun swap).
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '3.5rem' }}>
          <button
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: 'white',
              border: '2.5px solid #0B4571',
              color: '#0B4571',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.65rem 1.3rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}
            onClick={() => navigate('/get-yours')}
          >
            Pelajari Lebih Lanjut <ArrowRight fill="#fff" />
          </button>
        </div> */}
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '2.5rem' }}>
          {/* CHARGE */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
            <img loading='lazy'  alt='PBB' src={PBB} style={{ width: '100%' }} />
            <div style={{ padding: '1.25rem', backgroundColor: 'white', borderRadius: '0.85rem', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px' }}>
              <div style={{ fontFamily: 'Gilroy-Medium', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                <ul>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div>Garansi baterai 5 tahun/50 ribu km</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Extended Warranty part hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Extended Warranty dinamo hingga 30 ribu km/3 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Home Service 1 tahun</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Emergency Road Assistance 1 tahun</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* SWAP */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
            <img loading='lazy'  alt='PSB' src={PSB} style={{ width: '100%' }} />
            <div style={{ padding: '1.25rem', backgroundColor: 'white', borderRadius: '0.85rem', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px' }}>
              <div style={{ fontFamily: 'Gilroy-Medium', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                <ul>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> sewa baterai selama 12 bulan</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Warranty part hingga 10 ribu km/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Warranty dinamo hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* INTRO */}
      {/* <div style={{ padding: '4rem 2rem 4rem 2rem' }}>
        <div style={{
          paddingLeft: '1.25rem',
          fontFamily: 'Gilroy-ExtraBold',
          fontSize: '1.5rem',
          color: '#103856',
          marginBottom: '0.5rem',
        }}>
          Heading
        </div>
        <div style={{
          paddingLeft: '1.25rem',
          fontFamily: 'Gilroy-ExtraBold',
          fontSize: '1rem',
          color: '#103856',
          marginBottom: '1rem',
        }}>
          Sub Heading
        </div>
        <div style={{
          paddingLeft: '1.25rem',
          fontFamily: 'Gilroy-SemiBold',
          fontSize: '0.75rem',
          color: '#71869C',
          marginBottom: '1.25rem',
          borderLeft: '0.25rem solid #1ECECA',
        }}>
          Terintegrasi dengan ratusan jaringan BSS (Battery Swap Station) di Jakarta, Anda akan merasakan kemudahan mendapatkan baterai yang selalu baru dalam hitungan detik.
        </div>
        <img loading='lazy'  alt='mickey-square' src={MickeySquare} style={{ width: '100%' }} />
      </div> */}

      {/* FAST & EFFICIENT 1 */}
      {/* <div style={{
        backgroundColor: 'white',
        padding: '3rem 2rem',
        borderRadius: '1.5rem',
        marginBottom: '4rem',
      }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', marginBottom: '1.5rem' }}>
          Efisien dan Cepat, <br />
          Selalu Tepat Waktu
        </div>
        <img loading='lazy'  alt='mickey-square' src={MickeySquare} style={{ width: '100%', marginBottom: '1.5rem' }} />
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', marginBottom: '2.5rem' }}>
          Ratusan BSS yang tersebar di seluruh Jadetabek siap layani kamu untuk tukar baterai dalam hitungan detik. Super sat-set!
        </div>
        <button style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#0B4571',
          border: 'none',
          color: 'white',
          fontFamily: 'Gilroy-SemiBold',
          padding: '0.75rem 1.5rem',
          borderRadius: '0.5rem',
          fontSize: '1rem',
        }}>
          See More <ArrowRight fill="#fff" />
        </button>
      </div> */}

      {/* CHOOSE COLOR */}
      <div style={{ padding: '4rem 0rem 4rem 0rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.5rem', color: '#103856', textAlign: 'center', marginBottom: '1rem' }}>
          Pilih Warna <span style={{ color: '#1ECECA' }}>Sesuai Gayamu</span>
        </div>
        {/* IMG PREVIEW */}
        <div style={{ position: 'relative', width: '100%' }}>
          <div
            id="color-carousel"
            style={{
              margin: '0 auto',
              display: 'flex',
              overflowX: 'scroll',
              scrollSnapType: 'x mandatory',
              scrollSnapStop: 'always',
              overflowX: 'hidden',
            }}
            onScroll={(e) => {
              const container = e.target
              const scrollPosition = container.scrollLeft
              const containerWidth = container.clientWidth
              const newIndex = Math.round(scrollPosition / containerWidth)
              setColorCurrentIndex(newIndex)
            }}
          >
            {renderColorCarousel()}
            {/* PREV BUTTON */}
            <button
              onClick={() => handleScrollColorCarousel(-1)}
              style={{
                position: 'absolute',
                top: '35%',
                left: '15px',
                transform: 'translateY(-35%)',
                backgroundColor: 'white',
                color: '#1ECECA',
                border: 'none',
                borderRadius: '50%',
                padding: '1px 11.5px 2.5px 11.5px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                display: currentColorIndex === 0 ? 'none' : 'block',
              }}
            >
              &#8249;
            </button>
            {/* NEXT BUTTON */}
            <button
              onClick={() => handleScrollColorCarousel(1)}
              style={{
                position: 'absolute',
                top: '35%',
                right: '15px',
                transform: 'translateY(-35%)',
                backgroundColor: 'white',
                color: '#1ECECA',
                border: 'none',
                borderRadius: '50%',
                padding: '1px 11.5px 2.5px 11.5px',
                cursor: 'pointer',
                fontSize: '1.5rem',
                display: currentColorIndex === ColorList.length-1 ? 'none' : 'block',
              }}
            >
              &#8250;
            </button>
          </div>
        </div>
        {/* COLOR SELECTOR */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '2.25rem' }}>
          <div style={{
            padding: '0.8rem 1rem',
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.75rem',
            boxShadow: '0px 4px 20px 0px rgba(0,0,0,0.1)',
            width: 'fit-content',
            borderRadius: '0.625rem',
            marginBottom: '1.25rem',
            backgroundColor: 'white',
          }}>
            {renderColorBar()}
          </div>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1rem' }}>
            {ColorList[currentColorIndex].color}
          </div>
        </div>
        {/* SPECIFICATION */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{
            backgroundColor: '#FFFFFF',
            padding: '1.25rem',
            borderRadius: '1.25rem',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            rowGap: '1rem',
            justifyContent: 'center',
            margin: '0rem 2rem',
          }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                65 km/jam
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Kecepatan Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                65 km
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Jarak Tempuh Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                1.8 KwH
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Kapasitas Baterai
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                Cakram Ganda
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Sistem Pengereman
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                150 Nm
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Torsi Maks.
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', rowGap: '0.2rem' }}>
              <ColoredText>
                3 kW
              </ColoredText>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.625rem', color: '#103856', textAlign: 'center' }}>
                Output Tenaga Maks.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* GET NOW */}
      <div style={{ paddingBottom: '4rem' }}>
        <div style={{ width: '100%', position: 'relative' }}>
          <img loading='lazy'  alt='getnow-placeholder' src={GetNowPlaceholderMobile} style={{ width: '100%' }} />
          <div style={{
            width: '75%',
            padding: '2rem',
            backgroundColor: 'white',
            borderRadius: '0 0.75rem 0.75rem 0',
            position: 'absolute',
            top: '7.5%',
            left: '0',
            transform: 'translateY(-7.5%)',
          }}>
            <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '1.125rem', marginBottom: '1rem' }}>
              Dapatkan Sekarang!
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '0.75rem', color: '#71869C', marginBottom: '2.5rem' }}>
              Tersedia pilihan paket Electrum yang menarik melalui variasi metode pembayaran yang kamu inginkan.
            </div>
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
              }}
              onClick={() => navigate('/get-yours')}
            >
              Dapatkan H1 <ArrowRight fill="#fff" />
            </button>
          </div>
        </div>
      </div>

    </div>
  )
}

export default H3
