import * as React from "react";

const Verified = ({ size = '72' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill="none"
    viewBox="0 0 72 72"
  >
    <circle cx="36" cy="36" r="16" fill="#fff"></circle>
    <path
      fill="url(#paint0_linear_7451_1023)"
      d="m32.86 38.106-4.35-4.275q-.826-.825-2.062-.825t-2.139.9q-.825.825-.825 2.1t.825 2.1l6.45 6.45q.9.9 2.1.9t2.1-.9l12.75-12.75q.9-.9.861-2.1t-.86-2.1q-.9-.9-2.137-.936-1.236-.036-2.139.861zm-8.4 27.15-4.35-7.35-8.25-1.8a2.83 2.83 0 0 1-1.8-1.161 2.78 2.78 0 0 1-.526-2.064l.825-8.475-5.625-6.45q-.75-.825-.75-1.95t.75-1.95l5.625-6.45-.825-8.475q-.15-1.125.525-2.064a2.82 2.82 0 0 1 1.8-1.161l8.25-1.8 4.35-7.35q.6-.975 1.65-1.314t2.1.114l7.8 3.3 7.8-3.3q1.05-.45 2.1-.114t1.65 1.314l4.35 7.35 8.25 1.8q1.125.225 1.8 1.164t.525 2.061l-.825 8.475 5.625 6.45q.75.825.75 1.95t-.75 1.95l-5.625 6.45.825 8.475q.15 1.125-.525 2.064a2.82 2.82 0 0 1-1.8 1.161l-8.25 1.8-4.35 7.35q-.6.975-1.65 1.314t-2.1-.114l-7.8-3.3-7.8 3.3q-1.05.45-2.1.114t-1.65-1.314"
    ></path>
    <defs>
      <linearGradient
        id="paint0_linear_7451_1023"
        x1="36.009"
        x2="36.009"
        y1="5.297"
        y2="66.715"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1ECECA"></stop>
        <stop offset="1" stopColor="#103856"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default Verified;
