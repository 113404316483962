import React, { useContext, useRef, useState } from 'react'
import { Footer, Header } from 'components'
import { Context } from 'helpers/language-provider'
import { FormattedMessage } from 'react-intl'

import { ArrowLeft, ArrowRight } from 'assets/icons'
import { ADIRA, AEON, BRI, CIMB, GetNowPlaceholder, GetYoursGIF, PBB, PSB, PlaceholderSquare, SepekanKVBanner, TRANSPACIFIC } from 'assets/images'
import { Button, Input, Steps, message } from 'antd'
import { WhatsAppOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'

const { TextArea } = Input
const { Step } = Steps

const rectanglePlaceholder = 'https://knetic.org.uk/wp-content/uploads/2020/07/Pcture-Placeholder.png'

// OLD URL
const bookingFeeURL = 'https://electrum.halosis.id/category/sub/677771/booking-fee'
const PBBFullURL = 'https://electrum.halosis.id/category/sub/677773/h3i-pbb'
const PSBFullURL = 'https://electrum.halosis.id/category/sub/677772/h3i-psb'

// NEW URL
const bookingFeeURLNew = 'https://electrum.halosis.id/product/229246/h3i-booking-fee-only#'
const PBBFullURLNew = 'https://electrum.halosis.id/product/229758/electrum-h3i-pbb'
const PSBFullURLNew = 'https://electrum.halosis.id/product/229757/electrum-h3i-psb'

const GetYours = () => {
  const { locale } = useContext(Context)
  const defaults = require('languages/id.json')
  const navigate = useNavigate()

  // BUTTON STYLES
  const btnStyleBlue = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#1ECECA',
    border: 'none',
    color: 'white',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
  }
  const btnStyleWhite = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
    border: 'none',
    color: 'black',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
  }

  // CUSTOM BUTTON STYLE
  const [isHoveredPBB, setIsHoveredPBB] = useState(false)
  const [isHoveredPSB, setIsHoveredPSB] = useState(false)
  const [isHoveredCash, setIsHoveredCash] = useState(false)
  const [isHoveredLeasing, setIsHoveredLeasing] = useState(false)
  const [isHoveredBookFee, setIsHoveredBookFee] = useState(false)
  const [isHoveredFullPay, setIsHoveredFullPay] = useState(false)
  const [isHoveredAEON, setIsHoveredAEON] = useState(false)
  const [isHoveredADIRA, setIsHoveredADIRA] = useState(false)
  const [isHoveredCIMB, setIsHoveredCIMB] = useState(false)
  const [isHoveredBRI, setIsHoveredBRI] = useState(false)
  const [isHoveredTranspacific, setIsHoveredTranspacific] = useState(false)
  const buttonStyle = (isHovered) => ({
    cursor: 'pointer',
    width: '100%',
    backgroundColor: isHovered ? '#0B4571' : 'white',
    border: 'none',
    color: isHovered ? 'white' : '#0B4571',
    border: '2px solid #0B4571',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
  })
  const buttonStyleSelected = {
    cursor: 'pointer',
    width: '100%',
    backgroundColor: '#0B4571',
    border: 'none',
    color: 'white',
    border: '2px solid #0B4571',
    fontFamily: 'Gilroy-SemiBold',
    padding: '0.75rem 1.5rem',
    borderRadius: '0.5rem',
  }

  // CHOOSE PKG STATE
  const [batteryPackage, setBatteryPackage] = useState('')
  const [paymentMethod, setPaymentMethod] = useState('')
  const [leasingOpt, setLeasingOpt] = useState('')
  const [cashOpt, setCashOpt] = useState('')

  // CHOOSE PKG COMPONENT
  const RenderChooseBatteryPKG = () => {
    return (
      <div>
        {/* METHODS */}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '1.5rem' }}>
          {/* CHARGE */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
            <img loading='lazy'  alt='PBB' src={PBB} style={{ width: '100%' }} />
            <div style={{
              padding: '1.5rem 2rem',
              backgroundColor: 'white',
              borderRadius: '0.85rem',
              minHeight: '22rem',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                <ul>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div>Garansi baterai 5 tahun/50 ribu km</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Extended Warranty part hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Extended Warranty dinamo hingga 30 ribu km/3 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Home Service 1 tahun</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Emergency Road Assistance 1 tahun</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                  </li>
                </ul>
              </div>
              <div style={{ margin: '1.5rem 0 0.5rem 0' }}>
                <button
                  style={batteryPackage === 'PBB' ? buttonStyleSelected : buttonStyle(isHoveredPBB)}
                  // onMouseEnter={() => setIsHoveredPBB(true)}
                  // onMouseLeave={() => setIsHoveredPBB(false)}
                  onClick={() => setBatteryPackage('PBB')}
                >
                  Pilih Paket PBB
                </button>
              </div>
            </div>
          </div>
          {/* SWAP */}
          <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem', alignItems: 'center' }}>
            <img loading='lazy'  alt='PSB' src={PSB} style={{ width: '100%' }} />
            <div style={{
              padding: '1.5rem 2rem',
              backgroundColor: 'white',
              borderRadius: '0.85rem',
              minHeight: '22rem',
              boxShadow: 'rgba(0, 0, 0, 0.1) 0px 3px 12px -2px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
              <div style={{ fontFamily: 'Gilroy-Medium', fontSize: '0.85rem', color: 'rgb(81, 102, 146)', marginLeft: '-1rem' }}>
                <ul>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> sewa baterai selama 12 bulan</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Warranty part hingga 10 ribu km/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Warranty dinamo hingga 20 ribu km/2 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> Service Berkala 4 kali dan Service Spare Part 1 kali/1 tahun (mana yang tercapai dahulu)</div>
                  </li>
                  <li style={{ marginBottom: '0.85rem' }}>
                    <div><b>GRATIS</b> helm Electrum dan toolkit</div>
                  </li>
                </ul>
              </div>
              <div style={{ margin: '1.5rem 0 0.5rem 0' }}>
                <button
                  style={batteryPackage === 'PSB' ? buttonStyleSelected : buttonStyle(isHoveredPSB)}
                  // onMouseEnter={() => setIsHoveredPSB(true)}
                  // onMouseLeave={() => setIsHoveredPSB(false)}
                  onClick={() => setBatteryPackage('PSB')}
                >
                  Pilih Paket PSB
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const RenderChoosePaymentMethod = () => {
    return (
      <div>
        {/* METHODS */}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '1.5rem' }}>
          {/* CASH */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '1.25rem', padding: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
              Tunai
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
              Tersedia metode pembayaran debit dan kredit.
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={paymentMethod === 'cash' ? buttonStyleSelected : buttonStyle(isHoveredCash)}
                // onMouseEnter={() => setIsHoveredCash(true)}
                // onMouseLeave={() => setIsHoveredCash(false)}
                onClick={() => setPaymentMethod('cash')}
              >
                Pilih Menggunakan Tunai
              </button>
            </div>
          </div>
          {/* LEASING */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '1.25rem', padding: '2rem' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
              Leasing
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
              Ajukan leasing ke perusahaan terpercaya Electrum.
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={paymentMethod === 'leasing' ? buttonStyleSelected : buttonStyle(isHoveredLeasing)}
                // onMouseEnter={() => setIsHoveredLeasing(true)}
                // onMouseLeave={() => setIsHoveredLeasing(false)}
                onClick={() => setPaymentMethod('leasing')}
              >
                Pilih Menggunakan Leasing
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const RenderChooseCash = () => {
    return (
      <div>
        {/* METHODS */}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: '1.5rem' }}>
          {/* BOOKING FEE */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '1.25rem', padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
              Biaya Pemesanan
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
              Amankan stok motor listrik Electrum sebelum kehabisan dengan biaya pemesanan yang rendah.
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={cashOpt === 'bookfee' ? buttonStyleSelected : buttonStyle(isHoveredBookFee)}
                // onMouseEnter={() => setIsHoveredBookFee(true)}
                // onMouseLeave={() => setIsHoveredBookFee(false)}
                onClick={() => window.open(bookingFeeURLNew, '_blank')}
              >
                Bayar Booking Fee
              </button>
            </div>
          </div>
          {/* FULL PAYMENT */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '1.25rem', padding: '2rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
              Pembayaran Penuh
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
              Lebih tangguh dengan pembayaran penuh.
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={cashOpt === 'full' ? buttonStyleSelected : buttonStyle(isHoveredFullPay)}
                // onMouseEnter={() => setIsHoveredFullPay(true)}
                // onMouseLeave={() => setIsHoveredFullPay(false)}
                onClick={() => {
                  if (batteryPackage === 'PBB') {
                    window.open(PBBFullURLNew, '_blank')
                  } else {
                    window.open(PSBFullURLNew, '_blank')
                  }
                }}
              >
                Beli H3i
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  const RenderChooseLease = () => {
    return (
      <div>
        {/* HEADLINE */}
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.35rem', color: '#103856', marginBottom: '2rem' }}>
          Ajukan leasing Anda kepada perusahan terpercaya berikut, motor listrik Electrum aman dikirim ke alamat Anda. 
        </div>
        {/* LEASING LIST */}
        <div style={{ display: 'flex', overflowX: 'scroll', columnGap: '1.5rem' }}>
          {/* AEON */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '1.25rem', padding: '2rem', width: '11.5rem', maxWidth: '11.5rem', flexShrink: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <img loading='lazy'  alt='leasingOpt' src={AEON} style={{ display: 'block', maxWidth: '10rem', minHeight: '5rem', objectFit: 'contain', margin: '0 auto 1.5rem auto' }} />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
                AEON Leasing
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
                DP mulai dari 1,5 juta
              </div>
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={leasingOpt === 'aeon' ? buttonStyleSelected : buttonStyle(isHoveredAEON)}
                // onMouseEnter={() => setIsHoveredAEON(true)}
                // onMouseLeave={() => setIsHoveredAEON(false)}
                onClick={() => window.open('https://drive.google.com/file/d/1hyLGGakdD4iJyS51NkSowfKpXhdlRqT4/view?usp=drive_link', '_blank')}
              >
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div>
          {/* ADIRA */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '1.25rem', padding: '2rem', width: '11.5rem', maxWidth: '11.5rem', flexShrink: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <img loading='lazy'  alt='leasingOpt' src={ADIRA} style={{ display: 'block', maxWidth: '10rem', minHeight: '5rem', objectFit: 'contain', margin: '0 auto 1.5rem auto' }} />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
                ADIRA Leasing
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
                Cicilan mulai Rp 500 ribuan/bulan
              </div>
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={leasingOpt === 'adira' ? buttonStyleSelected : buttonStyle(isHoveredADIRA)}
                // onMouseEnter={() => setIsHoveredADIRA(true)}
                // onMouseLeave={() => setIsHoveredADIRA(false)}
                onClick={() => window.open('https://drive.google.com/file/d/1voFH8UQgdHXIpyHOlwjjwNShK8p6UDHI/view?usp=drive_link', '_blank')}
              >
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div>
          {/* CIMB */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '1.25rem', padding: '2rem', width: '11.5rem', maxWidth: '11.5rem', flexShrink: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <img loading='lazy'  alt='leasingOpt' src={CIMB} style={{ display: 'block', maxWidth: '10rem', minHeight: '5rem', objectFit: 'contain', margin: '0 auto 1.5rem auto' }} />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
                CIMB Leasing
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
                DP mulai 0%
              </div>
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={leasingOpt === 'cimb' ? buttonStyleSelected : buttonStyle(isHoveredCIMB)}
                // onMouseEnter={() => setIsHoveredCIMB(true)}
                // onMouseLeave={() => setIsHoveredCIMB(false)}
                onClick={() => window.open('https://drive.google.com/file/d/15RiLU63vpzk1knwxt892eH3T91Clpnpj/view?usp=drive_link', '_blank')}
              >
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div>
          {/* BRI */}
          {/* <div style={{ border: '2px solid #1ECECA', borderRadius: '1.25rem', padding: '2rem', width: '11.5rem', maxWidth: '11.5rem', flexShrink: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <img loading='lazy'  alt='leasingOpt' src={BRI} style={{ display: 'block', maxWidth: '10rem', minHeight: '5rem', objectFit: 'contain', margin: '0 auto 1.5rem auto' }} />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
                BRI Leasing
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
                DP mulai dari 1,5 juta
              </div>
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={leasingOpt === 'bri' ? buttonStyleSelected : buttonStyle(isHoveredBRI)}
                // onMouseEnter={() => setIsHoveredBRI(true)}
                // onMouseLeave={() => setIsHoveredBRI(false)}
                onClick={() => setLeasingOpt('bri')}
              >
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div> */}
          {/* TRANSPACIFIC */}
          <div style={{ border: '2px solid #1ECECA', borderRadius: '1.25rem', padding: '2rem', width: '11.5rem', maxWidth: '11.5rem', flexShrink: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <img loading='lazy'  alt='leasingOpt' src={TRANSPACIFIC} style={{ display: 'block', maxWidth: '10rem', minHeight: '5rem', objectFit: 'contain', margin: '0 auto 1.5rem auto' }} />
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', textAlign: 'center', marginBottom: '0.85rem' }}>
                Transpacific Leasing
              </div>
              <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', textAlign: 'center', marginBottom: '1rem' }}>
                DP mulai dari 3,5 juta
              </div>
            </div>
            <div style={{ margin: '1.5rem 0 0 0' }}>
              <button
                style={leasingOpt === 'transpacific' ? buttonStyleSelected : buttonStyle(isHoveredTranspacific)}
                // onMouseEnter={() => setIsHoveredTranspacific(true)}
                // onMouseLeave={() => setIsHoveredTranspacific(false)}
                onClick={() => window.open('https://drive.google.com/file/d/1dbTJKErTkOE2N_t4o5ja2J1N-SLNCybZ/view?usp=drive_link', '_blank')}
              >
                Pelajari Lebih Lanjut
              </button>
            </div>
          </div>
        </div>
        {/* CONTACT US */}
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '2px solid #1ECECA',
          borderRadius: '1.25rem',
          padding: '2rem',
          marginTop: '2rem',
        }}>
          <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.5rem', color: '#103856' }}>
            Hubungi kami untuk rate leasing terbaik!
          </div>
          <div>
            <button
              style={{
                cursor: 'pointer',
                width: '100%',
                backgroundColor: '#25D366',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
              }}
              onClick={() => window.open('https://api.whatsapp.com/send/?phone=6285190013535', '_blank')}
            >
              Hubungi Whatsapp &nbsp; <WhatsAppOutlined />
            </button>
          </div>
        </div>
      </div>
    )
  }

  // CUSTOM JS FUNCTION
  const capitalizeFirstLetter = (str) => {
    return String(str).charAt(0).toUpperCase() + String(str).slice(1);
  }
  const batterPkgNaming = (pkg) => {
    let naming
    switch (pkg) {
      case 'PBB':
        return naming = 'Beli Baterai'
      case 'PSB':
        return naming = 'Sewa Baterai'
      default:
        return naming = null
    }
  }

  // STEPS CONTENT
  const steps = [
    {
      title: 'Pilih Paket Baterai',
      description: batteryPackage !== '' ? `Pilihan: \n ${batterPkgNaming(batteryPackage)}` : null,
      content: <RenderChooseBatteryPKG />,
    },
    {
      title: 'Metode Pembayaran',
      description: paymentMethod !== '' ? `Pilihan: ${capitalizeFirstLetter((paymentMethod === 'cash' ? 'Tunai' : paymentMethod))}` : null,
      content: <RenderChoosePaymentMethod />,
    },
    {
      title: paymentMethod === 'leasing' ? 'Pilihan Leasing' : 'Pilihan Pembayaran',
      content: paymentMethod === 'cash'
        ? <RenderChooseCash />
        : <RenderChooseLease />,
    },
  ]
  const [current, setCurrent] = useState(0)
  const getStepClass = (index) => {
    if (index !== current) {
      return 'next-step'
    }
    return ''
  }
  const next = () => {
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }
  const items = steps.map((item, index) => ({
    key: item.title,
    title: item.title,
    description: item.description,
    className: getStepClass(index),
  }))
  const canProceed = (step) => {
    if (step === 0) return batteryPackage !== ''
    if (step === 1) return paymentMethod !== ''
    if (step === 2) return cashOpt !== ''
    return false
  }
  const handleOptionSelect = (option) => {
    if (current === 0) {
      setBatteryPackage(option)
    } else if (current === 1) {
      setPaymentMethod(option)
    } else if (current === 2) {
      setCashOpt(option)
    }
  }
  const RenderStepsContent = () => {
    return (
      <div style={{ padding: '3rem', border: '2px solid #D7F3F3', borderRadius: '2rem' }}>
        <Steps current={current} items={items} style={{ marginBottom: '3rem' }} />
        <div>
          {/* {steps[current].content} */}
          {React.cloneElement(steps[current].content, { handleOptionSelect })}
        </div>
        <div style={{ marginTop: '2.5rem', display: 'flex', columnGap: '0.75rem' }}>
          {current > 0 && (
            <button
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                border: 'none',
                color: '#0B4571',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                border: '2px solid #0B4571',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
              }}
              onClick={() => {
                prev()
                document.getElementById('steps-section')?.scrollIntoView()
              }}
            >
              <ArrowLeft fill="#0B4571" /> Kembali
            </button>
          )}
          {current < steps.length - 1 && (
            <button
              style={{
                cursor: canProceed(current) ? 'pointer' : 'not-allowed',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#0B4571',
                border: 'none',
                color: 'white',
                fontFamily: 'Gilroy-SemiBold',
                padding: '0.75rem 1.5rem',
                borderRadius: '0.5rem',
                fontSize: '1rem',
                width: 'fit-content',
                opacity: canProceed(current) ? 1 : 0.5,
              }}
              onClick={() => {
                canProceed(current) && next()
                document.getElementById('steps-section')?.scrollIntoView()
              }}
              disabled={!canProceed(current)}
            >
              Berikutnya <ArrowRight fill="#fff" />
            </button>
          )}
        </div>
      </div>
    )
  }

  // LEASING LIST
  const leasingList = [
    {
      img: rectanglePlaceholder,
      lease: 'Leasing 1',
      desc: 'This section explains the available options to get H3, through ownership model (leasing) and rental.',
    },
    {
      img: rectanglePlaceholder,
      lease: 'Leasing 1',
      desc: 'This section explains the available options to get H3, through ownership model (leasing) and rental.',
    },
    {
      img: rectanglePlaceholder,
      lease: 'Leasing 1',
      desc: 'This section explains the available options to get H3, through ownership model (leasing) and rental.',
    },
    {
      img: rectanglePlaceholder,
      lease: 'Leasing 1',
      desc: 'This section explains the available options to get H3, through ownership model (leasing) and rental.',
    },
    {
      img: rectanglePlaceholder,
      lease: 'Leasing 1',
      desc: 'This section explains the available options to get H3, through ownership model (leasing) and rental.',
    },
  ]
  const renderLeasingList = () => {
    return leasingList.map((item, index) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <img loading='lazy'  alt='placeholder' src={item.img} style={{ width: '100%', borderRadius: '0.85rem', marginBottom: '1.25rem' }} />
        <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.75rem', marginBottom: '0.86rem' }}>
          {item.lease}
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1rem', color: '#71869C', marginBottom: '1.25rem' }}>
          {item.desc}
        </div>
        <button style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#0B4571',
          border: 'none',
          color: 'white',
          fontFamily: 'Gilroy-SemiBold',
          padding: '0.75rem 1.5rem',
          borderRadius: '0.5rem',
          fontSize: '1rem',
          width: 'fit-content',
        }}>
          See More <ArrowRight fill="#fff" />
        </button>
      </div>
    ))
  }

  return (
    <div>
      <Header />

      {/* BANNER STATIC */}
      <div style={{
        width: '100vw',
        height: '90vh',
        margin: '0rem auto',
        backgroundColor: 'white',
      }}>
        <div
          alt="Banner Placeholder"
          style={{
            backgroundImage: `url('${GetYoursGIF}')`,
            backgroundSize: 'cover',
            width: '100vw',
            height: '100%',
            scrollSnapAlign: 'start',
          }}
        >
          <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: '6.5rem' }}>
            {/* HEADLINE & SUBHEADLINE */}
            <div style={{ fontFamily: 'Gilroy-Bold', color: '#0B4D7F', fontSize: '4.5rem' }}>
              Dapatkan <br /> Electrum Sekarang
            </div>
            <div style={{ fontFamily: 'Gilroy-SemiBold', color: '#0B4D7F', fontSize: '2.5rem' }}>
              Mudah & Aman
            </div>
            {/* BUTTONS */}
            {/* <div style={{ display: 'flex', columnGap: '1rem', marginTop: '6.5rem' }}>
              <button style={btnStyleBlue}>
                Pre-order Now <ArrowRight fill="#fff" />
              </button>
              <button style={btnStyleWhite}>
                Rent Now <ArrowRight fill="#000" />
              </button>
            </div> */}
          </div>
        </div>
      </div>

      {/* CHOOSE PKG */}
      <div style={{ padding: '6.5rem 12.5rem 4rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.5rem', color: '#103856', textAlign: 'center', marginBottom: '1.75rem' }}>
          Pilih Paketnya, Bungkus Motornya
        </div>
        <div id='steps-section' style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.25rem', color: '#71869C', textAlign: 'center', marginBottom: '3.75rem' }}>
          Tersedia pilihan paket Electrum yang menarik melalui variasi metode <br /> pembayaran yang kamu inginkan.
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1.5rem' }}>
          {RenderStepsContent()}
        </div>
      </div>

      {/* SEPEKAN BANNER */}
      <div style={{ padding: '0rem 12.5rem', marginBottom: '4rem' }}>
        <img loading='lazy' 
          alt='sepekan-banner'
          src={SepekanKVBanner}
          onClick={() => navigate('/sepekan-form')}
          style={{ width: '100%', borderRadius: '1.5rem', cursor: 'pointer' }}
        />
      </div>

      {/* HOW IT WORKS */}
      {/* <div style={{ padding: '6.5rem 12.5rem' }}>
        <div style={{ fontFamily: 'Gilroy-ExtraBold', fontSize: '3.5rem', color: '#103856', marginBottom: '1.75rem' }}>
          Sesederhana ini cara kerjanya!
        </div>
        <div style={{ fontFamily: 'Gilroy-SemiBold', fontSize: '1.25rem', color: '#71869C', width: '65%', marginBottom: '3.75rem' }}>
          This section explains the available options to get H3, through ownership model (leasing) and rental.
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', columnGap: '3rem', rowGap: '3rem' }}>
          {renderLeasingList()}
        </div>
      </div> */}

      {/* FORM */}
      {/* <div style={{ padding: '6.5rem 0rem', backgroundColor: '#F4F7FA' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', paddingRight: '12.5rem' }}>
          <img loading='lazy'  alt='placeholder' src={PlaceholderSquare} style={{ width:'50%', borderRadius: '0 3rem 3rem 0' }} />
          <div style={{ padding: '2.5rem', backgroundColor: 'white', borderRadius: '1.25rem' }}>
            <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '1.25rem', color: '#103856', marginBottom: '1.5rem' }}>
              Heading
            </div>
            <div style={{ display: 'flex', columnGap: '1.5rem', marginBottom: '1.25rem' }}>
              <div>
                <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                  Nama Depan
                </div>
                <Input name='fistName' />
              </div>
              <div>
                <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                  Nama Belakang
                </div>
                <Input name='lastName' />
              </div>
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Nomor Telefon
              </div>
              <Input name='phoneNumber' type='number' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Alamat
              </div>
              <TextArea rows={4} name='address' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Label
              </div>
              <Input name='label' />
            </div>
            <div style={{ marginBottom: '1.25rem' }}>
              <div style={{ fontFamily: 'Gilroy-Bold', fontSize: '0.75rem', color: '#71869C', marginBottom: '0.25rem' }}>
                Label
              </div>
              <Input name='label' />
            </div>
            <button style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              backgroundColor: '#0B4571',
              border: 'none',
              color: 'white',
              fontFamily: 'Gilroy-SemiBold',
              padding: '0.75rem 1.5rem',
              borderRadius: '0.5rem',
              fontSize: '1rem',
            }}>
              Button Text
            </button>
          </div>
        </div>
      </div> */}

      <Footer />
    </div>
  )
}

export default GetYours
